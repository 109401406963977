<template>
  <div class="mainContainer container" style="">


    <div style="display:flex;">
      <back-button />
      <h5 class="pageTitle">Test</h5>
    </div>
    <hr>

    <div class="contentBody">


    </div>
  </div>
</template>

<script>
import { datetime, RRule, RRuleSet, rrulestr } from 'rrule'



export default {
  name: 'Test',
  components: {

  },
  data(){
    return{
      familyBas:[],
    }
  },
  computed:{
    activeUserBas(){
      return this.familyBas.filter(ba => ba.USER_ID === '3');
    }
  },
  methods:{

  },
  mounted() {
    this.familyBas = JSON.parse(localStorage.getItem('familyBas'));
    console.log('get familyBas');
    console.log(this.familyBas);
    console.log(this.activeUserBas);

    this.familyBas = this.familyBas.filter(ba => ba.BA_ID !== '2');
    console.log('get familyBas');
    console.log(this.familyBas);
    console.log(this.activeUserBas);
  }
}
</script>

<style scoped>
.contentBody{
  margin:10px;
  padding:10px;
  font-size:12px;
}
.section{
  margin-bottom:50px;
}
.faq{
  margin-bottom:10px;
}
.link{
  color:#0dcaf0;
  text-decoration: underline;
}


</style>
